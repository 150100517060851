<script lang="ts" setup>
import { useCalendarRegistry } from '~/composables/calendar-registry'

defineProps<{
  dayNumber: number
}>()

const { getShortNameOfWeekDay } = useCalendarRegistry()
</script>

<template>
  <div class="font-medium leading-5 py-3 text-aluminum text-center text-sm w-full">
    {{ getShortNameOfWeekDay(dayNumber) }}
  </div>
</template>
