<script lang="ts" setup>
import Day from '~/components/calendars/Day.vue'
import DayOfWeekShort from '~/components/calendars/DayOfWeekShort.vue'
import TimeOfDay from '~/components/calendars/TimeOfDay.vue'
import { useOrderStore } from '~/store/order'
import { useSpecialistStore } from '~/store/specialist'

const props = defineProps<{
  specialistId: string
}>()

const activeDate = ref<string | null>(null)
const activeTime = ref<string | null>(null)

const orderStore = useOrderStore()
const specialistStore = useSpecialistStore()
const { getSpecialistIntervals } = storeToRefs(specialistStore)

const getAvailableTimes = computed((): any[] => {
  if (!activeDate.value) {
    return []
  }

  return getSpecialistIntervals.value.find((x: any) => x.date === activeDate.value)?.timesOfDay ?? []
})

specialistStore.fetchSpecialistIntervals(props.specialistId)

watch(() => activeDate && activeTime, () => {
  orderStore.form.consultationData.startedAt = `${activeDate.value} ${activeTime.value}`
}, { deep: true })
</script>

<template>
  <div class="bg-light-gray p-4 rounded-[20px]">
    <div class="flex items-center justify-between">
      <b class="font-medium text-lg">Дата и время записи</b>
      <SvgIcon class="!size-5" name="outlined/calendar" />
    </div>

    <div class="mt-2">
      <div class="flex items-center justify-between -mr-2">
        <div
          v-for="(x, i) in getSpecialistIntervals"
          :key="i"
          class="basis-full flex flex-col items-center mr-2"
        >
          <DayOfWeekShort :day-number="x.dayNumber" />

          <Day
            :date="x.date"
            :is-active="activeDate === x.date"
            :is-disabled="x.isUnavailable"
            @selected="activeDate = x.date"
          />
        </div>
      </div>

      <FreeGallery class="mb-1 mt-4">
        <TimeOfDay
          v-for="(x, i) in getAvailableTimes"
          :key="i"
          :is-active="activeTime === x.time"
          :is-disabled="x.isUnavailable"
          :time="x.time"
          @selected="activeTime = x.time"
        />
      </FreeGallery>
    </div>
  </div>
</template>
